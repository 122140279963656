import axios from 'axios';

const URL = './api-temp/';
const URLAPI = 'https://dev.ranadeoro.com.mx/rest/booster';
//const URLAPI = 'http://dev-urrezko-igela.ntc3-p4stl.ezhostingserver.com/rest/booster';
export function getGameJSON(idGame){
  const request = axios.get(URLAPI+"/game/getGameInfo")
  .then(res => {
    if(res.data.status == true){
        let casting = res.data.result.game;
        casting.playInfo = res.data.result.playInfo;
        casting.playInfo.cartasTipoJuego = res.data.result.cartasTipoJuego;
        
        return casting;
    }else{
      return {}
    }

  });
  return{
    type:'game',
    payload: request
  }
}

export function getGame(idGame){
  const request = axios.post(URLAPI+"/game/getGameInfo?idGame="+idGame)
  
  .then(res => {
    if(res.data.status == 1){
        let casting = res.data.result.game;
        casting.playInfo = res.data.result.playInfo;
        casting.playInfo.cartasTipoJuego = res.data.result.cartasTipoJuego;        
        return casting;
    }else{
      return null;
    }

  });
  return{
    type:"game",
    payload: request
  }




}


export function undoLastCard(idJuego){
  console.log('Deshaciendo la ultima carta del juego idJuego: ', idJuego);

  const request = axios.post(URLAPI+"/game/undoLastCard?idJuego="+idJuego)
  .then(res => {
    if(res.data.status == true){
      console.log('La ultima carta se deshizo con exito '+ true+ " CARTA "+res.data.result);
        return res.data.result
    }else{
      console.log('La ultima carta no deshizo con exito ', false);
      return {}
    }

  });
  return{
    type:'undoLastCardAction',
    payload: request
  }
}

export function getCardsHistory(idPlay){
  const request = axios.post(URLAPI+"/game/getCardsHistory?idPlay="+idPlay)
  .then(res => {
    if(res.data.status == true){
        res.data.result.idjuegoInvoked = idPlay;
        return res.data.result
    }else{
      return {}
    }

  });
  return{
    type:'playHistory',
    payload: request
  }
}



export function getPlayerSearchJSON(username){
  const request = axios.get(URL+"SearchUser.json")
  .then(res => res.data);
  return{
    type:'playerSearch',
    payload: request
  }
}
export function getPlayerSearch(keyword,idGame){
  const request = axios.post(URLAPI+"/game/searchPlayers?nombreJugador="+keyword+"&idJuego="+idGame)
  .then(res => {
    if(res.data.status == 1){
      console.log("Busqueda de jugadores exitosa");
    }else{
      console.log("Error al encontrar tablas");
    }
    return res.data.result;

  });
  return{
    type:'playerSearch',
    payload: request
  }
}


export function getPlayerCardsJSON(idPlay){
  const request = axios.get(URL+"CardsPerUser.json")
  .then(res => res.data);
  return{
    type:'playerCards',
    payload: request
  }
}
export function getPlayerCards(idClient, idPlay){
  
  const request = axios.post(URLAPI+"/game/getTablesByUser?idCliente="+idClient+"&idJuego="+idPlay)
  .then(res => {
 
    let arrayTables = new Array();
    if(res.data.status == 1){
      let AllCardsPosiciones = res.data.result.tablesInfo;

      const dataProcesed = res.data.result.tables.map((table)=>{
          table.carta = 
          AllCardsPosiciones.filter(pos => {
            return pos.idtabla === table.idtabla;
          })
          arrayTables.push(table)
      })

    }else{
      console.log("Error al encontrar tablas");
    }
    return arrayTables;
  });
  return{
    type:'playerCards',
    payload: request
  }
}


export function getWinnersInfo(idPlay){
  const request = axios.post(URLAPI+"/game/getWinners?idJuego="+idPlay)
  .then(res => {
 
    let arrayTables = new Array();
    if(res.data.status == 1){
      console.log("Ganadores existentes");
      let AllCardsPosiciones = res.data.result.tablesInfo;
      const dataProcesed = res.data.result.tables.map((table)=>{
          table.carta = 
          AllCardsPosiciones.filter(pos => {
            return pos.idtabla === table.idtabla;
          })
          table.idJuego = idPlay;
          arrayTables.push(table)
      })
    }else{
      console.log("WINNERS:No hay ganador todavia");
    }
    return arrayTables;
  });
  return{
    type:'winnersInfo',
    payload: request
  }
}



export function acceptWinners(idPlay, idGame, _functionCallbak){  
  const request = axios.post(URLAPI+"/game/acceptWinners?idJuego="+idPlay)
  .then(res => {
    if(res.data.status == 1){
      console.log("GuardadoExitosamente ");
      return {requestData :idGame, functionCallbak : _functionCallbak};
    }else{
      console.log("No hay ganadores que guardar ... ");
    }
    return 0;
  });
  return{
    type:"acceptWinnersAction",
    payload: request
    //payload: {requestData :request, functionCallbak : _functionCallbak}
  }
}





export function newGameAction(idPlay){
  const request = axios.get(URL+"WinnersList.json")
  .then(res => res.data);
  return{
    type:'winnersInfo',
    payload: {}
  }
}

export function newCardSignPublish(idCard,idPlay){
  if(idCard === 0 && idPlay === 0){
    return{
      type:'newCardSign',
      payload: null
    }
  }
  const request = axios.post(URLAPI+"/game/singCardPPath?idGame="+idPlay+"&idCard="+idCard)
  .then(res => {
    
    if(res.data.status == 1){
      console.log("CARTA CARGADA "+idCard+" EN EL JUEGO "+idPlay)
    }else{
      console.log("CARTA "+idCard+" NO SE PUDO REGISTRAR EN EL JUEGO "+idPlay)
    }
    res.data.result.idCarta = res.data.result.idcarta;
    res.data.result.valida = res.data.status;
    return res.data.result;

  });
  return{
    type:'newCardSign',
    payload: request
  }
}


/*
export function getGame(){
    axios.get('./api-temp/GameInfo.json').then(res => console.log('AXIOS'+res.data));
   
          fetch('./api-temp/GameInfo.json').then(function (response) {
        
            const data = response.json()
            console.log('data: ', data);
            return data;
          }).then(function (result) {
           
            console.log(result.data);
    
          });
        
    return  {
        type:'game', 
        payload: {titulo:"INAUGURACION",descripcion:"20 de Mayo", juegoActual:1, juegoTotal:8 }
    }
}

async function apiGetGame(){
    const result = await axios('./api-temp/GameInfo.json');
    return result.data;
}

export async function getGame3() {
    const payload = await apiGetGame();
    console.log('payload: ', payload);
    return {
        type:'game',
        payload
    }
}
export function getGame33() {
    const resource = './api-temp/GameInfo.json';
    debugger
    return async (dispatch) => {
        debugger
      try {
        debugger
        const result = await axios.get(resource);
        
        dispatch({
          type: 'game',
          ...result.data
        });
      } catch (error) {
        console.error(`Error fetching ${resource}`, error);
        dispatch({
          type: 'game' // or, better, 'FAILED_ACTION' or something like that
        });
      }
    };
  }


export function getGameBAK(){
    const data = axios.get('./api-temp/GameInfo.json').then(res => res.data
        ,(error) => {console.log("Error AXIOS "+error )});     
    console.log('++++++ data: ', data);
    return  {
        type:'game',
        payload:  data
    }
}
*/